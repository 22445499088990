import React from 'react'

export default function Header() {
  return (
    <div>
      <div className="header">
        <h1>Drag and Drop Kanban Board</h1>
      </div>
    </div>
  )
}
